import { Guid } from '@/common/models/Guid';

export const ApiLocalKeys = {
  // Portal
  Company: 'komo::company',
  Workspace: 'komo::workspace',
  Administrator: 'komo::administrator',
  AdministratorSession: 'komo::administrator-session',

  // Site
  Site: {
    User: 'komo::user',
    PollSelected: 'komo::selected-card',
    Checklist: {
      Started: (cardId: Guid, userId: Guid) =>
        `komo::checklist::${cardId}::${userId}::started`
    },
    NumberGuess: {
      Guess: (cardId: Guid, userId: Guid) =>
        `komo::number-guessed::${cardId?.toString()}::${userId?.toString()}`,
      HasInteracted: (cardId: Guid, userId: Guid) =>
        `komo::number-guess-interact::${cardId?.toString()}::${userId?.toString()}`
    },
    Trivia: {
      Questions: (gameplayId: Guid) =>
        `komo::trivia::gameplay::${gameplayId?.toString()}::questions`,
      PageViews: (gameplayId: Guid) =>
        `komo::trivia::gameplay::${gameplayId}::pageViews`
    }
  },

  LegacyAdministrator: 'user-id',
  LegacyCompany: 'company-id'
};
